@use "@angular/material" as mat;

@mixin bulk-user-import-dialog-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $foreground: map-get($theme, foreground);

  app-bulk-user-import {
    color: mat.get-color-from-palette($primary, 900);
    .title {
      @include mat.typography-level($zonar-default-typography, subheading-1);
    }

    .description {
      @include mat.typography-level($zonar-default-typography, body-1);

      .url-file-sample {
        @include mat.typography-level($zonar-default-typography, body-2);
      }
    }

    .status-message {
      @include mat.typography-level($zonar-default-typography, body-1);
    }

    .result-column {
      @include mat.typography-level($zonar-default-typography, body-2);
    }

    a:link,
    a:visited {
      color: mat.get-color-from-palette($foreground, hyperlink);
    }
  }
}
