@mixin division-view-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $foreground: map-get($theme, foreground);

  app-division-view {
    .mat-tab-label {
      @include mat.typography-level($zonar-default-typography, body-2);
      font-weight: bold;
      color: mat.get-color-from-palette($primary, 900);
    }

    .company-info {
      h1 {
        @include mat.typography-level($zonar-default-typography, headline);
      }
    }

    button {
      @include mat.typography-level($zonar-default-typography, body-1);
      font-weight: bold;
    }

    .backlink {
      color: mat.get-color-from-palette($foreground, hyperlink);

      .backlink-icon {
        font-size: 0.75rem;
      }

      .backlink-text {
        @include mat.typography-level($zonar-default-typography, body-1);
      }
    }

    .mat-body {
      color: $pass;
    }

    .division-address-info, .division-level-info{
      @include mat.typography-level($zonar-default-typography, body-1);
      font-size: 0.875rem;
      color: mat.get-color-from-palette($primary, default);

      span {
        font-weight: bold;
      }
    }
  }
}
