@mixin user-list-group-policy-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  app-user-list-group-policy {
    div.group-policy-cell {
      .group-policy-cell-link {
        @include mat.typography-level($zonar-default-typography, body-1);
        color: mat.get-color-from-palette($primary, 900);
        font-weight: 600;
      }
    }

    .table-container .mat-table mat-cell.mat-column-edit {
      a.html-link {
        font-weight: 600;
        font-size: 0.875rem;
      }
    }

    div.group-policy-cell {
      max-width: 100%;

      .group-policy-cell-text {
        @include mat.typography-level($zonar-default-typography, body-1);
        color: mat.get-color-from-palette($primary, 900);
        margin: 0;
      }
    }

    .mat-column-edit a {
      border: 0.063rem solid mat.get-color-from-palette($background, icon-steel);
      padding: 0.063rem 0.5rem;
      text-decoration: none;
      font-weight: 600;
      color: #0075B4;
      border-radius: 0.188rem;
    }

    .filter-group-container {
      background-color: mat.get-color-from-palette($background, card);
      box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.30), 0px 2px 6px 2px rgba(0, 0, 0, 0.15);

      mat-divider.ng-star-inserted {
        width: 0.0625rem;
        height: 2rem;
        background: mat.get-color-from-palette($zonar-gray, 300);
      }
    }

  }
}
