@use 'node_modules/@angular/material' as mat;

@mixin division-dialog-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);

  app-division-dialog {
    .mat-dialog-content::-webkit-scrollbar-thumb {
      background: mat.get-color-from-palette($zonar-gray, 500);
    }

    .text-disable {
      @include mat.typography-level($zonar-default-typography, body-1);
    }

    .mat-dialog-title {
      @include mat.typography-level($zonar-default-typography, subheading-1);
    }

    .cancel-btn {
      @include mat.typography-level($zonar-default-typography, body-1);
      border-color: mat.get-color-from-palette($zonar-gray, 500);
    }

    .html-link {
      @include mat.typography-level($zonar-default-typography, body-1);
      color: mat.get-color-from-palette($accent, default);
    }
  }
}
