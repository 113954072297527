@use 'node_modules/@angular/material' as mat;

@mixin bulk-user-preview-dialog-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $foreground: map-get($theme, foreground);
  $background: map-get($theme, background);

  app-bulk-user-preview-dialog {
    .bulk-user-preview-dialog {
      .title {
        font-size: 1.5rem;
        font-weight: 600;
      }
  
      .description {
        font-size: 1rem;
      }

      .dialog-button {
        button {
          font-weight: bold;
          font-size: 1rem;
        }
      }

      .permission-item {
        font-size: 15px;
      }

      .bg-gray {
        font-size: 0.75rem;
        font-weight: bold;
        color: mat.get-color-from-palette($zonar-gray, 50);
        background-color: mat.get-color-from-palette($zonar-gray, 500);
      }

      .bg-green {
        background-color: $pass;
      }
    }
  }

}