@use 'node_modules/@angular/material' as mat;

@mixin list-management-theme($theme) {
  $primary: map-get($theme, primary);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  app-list-management {
    .app-table-container {
      zui-table.ss-table .mat-table {
        .mat-cell.mat-column-icon span.material-icons {
          font-size: 1.125rem;
        }
      }

      &.desktop-view {
        background-color: mat.get-color-from-palette($primary, 50);
      }
    }

    .filter-group {
      .title {
        .icon {
          font-size: 1.125rem;
        }

        .text {
          @include mat.typography-level($zonar-default-typography, body-1);
          color: mat.get-color-from-palette($background, 900);
        }
      }
    }

    a:link,
    a:visited {
      color: mat.get-color-from-palette($foreground, hyperlink);
    }

    .mat-menu-item {
      color: mat.get-color-from-palette($primary, 900);
      @include mat.typography-level($zonar-default-typography, body-1);
    }

    mat-icon.icon-button {
      border-color: $white;
    }
  }
}