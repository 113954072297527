@use 'node_modules/@angular/material' as mat;

@mixin bulk-edit-users-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $foreground: map-get($theme, foreground);

  app-bulk-edit-users {
    .page-title {
      @include mat.typography-level($zonar-default-typography, headline);
    }

    .section-title {
      @include mat.typography-level($zonar-default-typography, subheading-1);
    }

    .mat-form-field-appearance-outline {
      .mat-form-field-wrapper .mat-form-field-outline {
        color: mat.get-color-from-palette($primary, 500);
      }

      .mat-form-field-label {
        color: mat.get-color-from-palette($primary, default);
        font-size: 0.875rem;
      }

      .mat-form-field-infix {
        mat-select .mat-select-arrow-wrapper {
          transform: translateY(5%);
        }
      }
    }

    .admin-permission-description {
      color: mat.get-color-from-palette($primary, 700);
      font-size: 0.875rem;
    }

    .data-list {
      border-color: mat.get-color-from-palette($zonar-gray, 300);
    }

    .allow-line-break::first-line {
      font-weight: bold;
      font-size: larger;
    }

    .tooltip-size {
      font-size: 1.5rem;
    }

    .mat-body {
      color: mat.get-color-from-palette($primary, 900);
      @include mat.typography-level($zonar-default-typography, body-2);
      font-weight: normal;
    }

    .data-list-description {
      font-size: 0.875rem;
    }

    ::-webkit-scrollbar-thumb {
      background: $steel;
    }

    .permission-warn {
      font-size: 0.875rem;
      color: $critical;
    }

    .legend {
      font-size: 0.75rem;
    }

    .standard-color {
      // The color not existed on Material PL
      background-color: #969696;
    }

    .managed-color {
      background-color: $conti-7;
    }

    .admin-color {
      background-color: $silver;
    }

    .externally-managed-url {
      @include mat.typography-level($zonar-default-typography, body-2);
    }

    a:link, a:visited {
      color: mat.get-color-from-palette($foreground, hyperlink);
    }


    .externally-managed-bulk-user {
      font-style: italic;
      opacity: 0.6;
    }
  }
}
