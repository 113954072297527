@use 'node_modules/@angular/material' as mat;

@mixin company-management-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $foreground: map-get($theme, foreground);

  app-company-management {
    .company-management {
      h1 {
        @include mat.typography-level($zonar-default-typography, title);
      }
    }
  }
}
