@mixin card-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  .color-zonar-black {
    color: mat.get-color-from-palette($foreground, base);
  }

  .color-zonar-gray {
    color: mat.get-color-from-palette($foreground, text-breadcrumb);
  }

  .typography-header-20 {
    @include mat.typography-level($zonar-default-typography, subheading-1);
    font-size: 1.25rem;
    line-height: 2rem;
  }

  .typography-text-16 {
    @include mat.typography-level($zonar-default-typography, body-1);
    line-height: 1.5rem;

    &.bold {
      font-weight: 600;
    }
  }

  .color-zonar-blue {
    color: mat.get-color-from-palette($foreground, hyperlink);
  }
}
