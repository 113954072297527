@use 'node_modules/@angular/material' as mat;

@mixin app-user-list-container($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $foreground: map-get($theme, foreground);

  app-user-list-container {
    .mat-tab-label {
      @include mat.typography-level($zonar-default-typography, body-2);
      font-weight: bold;
      color: mat.get-color-from-palette($primary, 900);
    }

    .company-info {
      h1 {
        @include mat.typography-level($zonar-default-typography, headline);
      }
    }

    button {
      @include mat.typography-level($zonar-default-typography, body-1);
      font-weight: bold;
    }

    a:visited {
      color: mat.get-color-from-palette($accent, default)
    }

    .mat-body {
      color: $pass;
    }
    
    .company-address-info, .company-shortname-info {
      @include mat.typography-level($zonar-default-typography, body-1);
      font-size: 0.875rem;
      color: mat.get-color-from-palette($primary, default);
  
      span {
        font-weight: bold;
      }
    }

    .mat-form-field-appearance-outline {
      .mat-form-field-wrapper .mat-form-field-outline {
        color: mat.get-color-from-palette($primary, 500);
      }

      .mat-form-field-label {
        color: mat.get-color-from-palette($primary, default);
        font-size: 0.875rem;
      }

      .mat-form-field-infix {
        mat-select {
          .mat-select-value {
            @include mat.typography-level($zonar-default-typography, body-2);
            font-weight: 400;
          }
        }
      }

      .mat-input-element {
        font-size: 0.875rem;
      }
    }
  }
}
