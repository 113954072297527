@use "@angular/material" as mat;

@mixin app-bulk-edit-result-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $foreground: map-get($theme, foreground);

  app-bulk-edit-result {
    .bulk-edit-title {
      @include mat.typography-level($zonar-default-typography, subheading-1);
      color: mat.get-color-from-palette($primary, 900);
    }

    .bulk-edit-item {
      @include mat.typography-level($zonar-default-typography, body-1);
      color: mat.get-color-from-palette($primary, 900);
    }

    .message {
      @include mat.typography-level($zonar-default-typography, body-2);
      color: mat.get-color-from-palette($primary, 900);
    }

    .divider {
      border-top-color: $ghost;
    }

    ::-webkit-scrollbar-thumb {
      background: $steel;
    }

    .icon-success {
      color: mat.get-color-from-palette($foreground, icon-pass);
    }

    .icon-error {
      color: mat.get-color-from-palette($warn, darker);
    }
  }
}
