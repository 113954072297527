@use 'node_modules/@angular/material' as mat;

@mixin preview-dialog-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $foreground: map-get($theme, foreground);
  $background: map-get($theme, background);

  app-preview-dialog {
    .preview-dialog {
      .title {
        font-size: 1.5rem;
        font-weight: 600;
      }

      .description {
        font-size: 1rem;
      }

      .password-container {
        .password-default {
          color: $blue;
          background-color: $smoke;
        }

        .password-value {
          @include mat.typography-level($zonar-default-typography, display-2);
        }

        .description-title {
          @include mat.typography-level($zonar-default-typography, body-2);
        }

        .description-message {
          @include mat.typography-level($zonar-default-typography, caption);
        }
      }

      .dialog-button {
        button {
          font-weight: bold;
          font-size: 1rem;
        }
      }

      .contain-value {
        .value-item {
          border-bottom-color: mat.get-color-from-palette($zonar-gray, 300);

          .field-name {
            color: mat.get-color-from-palette($zonar-gray, default);
            font-size: 1rem;
          }

          .value-changed {
            font-weight: bold;
            font-size: 1rem;

            &.changed-color {
              color: mat.get-color-from-palette($zonar-gray, 500);
              font-weight: 200;
            }

            .array-item {
              border-bottom-color: mat.get-color-from-palette($zonar-gray, 300);
            }

            .show-button {
              color: mat.get-color-from-palette($zonar-blue, default);
              font-size: 0.813rem;
            }

            .bg-gray {
              font-size: 0.75rem;
              font-weight: bold;
              color: mat.get-color-from-palette($zonar-gray, 50);
              background-color: mat.get-color-from-palette($zonar-gray, 500);
            }

            .bg-green {
              background-color: $pass;
            }

            .local_shipping-active {
              color: $critical;
            }

            .local_shipping-inactive {
              color: $steel;
            }
          }
        }
      }
    }
  }
}
