@use 'node_modules/@angular/material' as mat;

@mixin announcement-dialog-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $foreground: map-get($theme, foreground);

  app-announcement-dialog {
    .announcement-container {
      color: mat.get-color-from-palette($primary, 900);
      .dialog-title {
        @include mat.typography-level($zonar-default-typography, subheading-1);
      }

      .description {
        @include mat.typography-level($zonar-default-typography, caption);

        .title-description {
          @include mat.typography-level($zonar-default-typography, body-2);
        }
      }

      .dialog-body {
        @include mat.typography-level($zonar-default-typography, body-1);
        border-color: $ghost;
      }

      .dialog-body-text {
        @include mat.typography-level($zonar-default-typography, title);
        color: mat.get-color-from-palette($foreground, icon-pass);
      }

      .mat-slide-toggle .mat-slide-toggle-thumb {
        background-color: mat.get-color-from-palette($accent, default);
      }
      .mat-slide-toggle .mat-slide-toggle-bar {
        background-color: rgba($blue, 0.54);
      }
    }
  }
}
