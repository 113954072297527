@use 'node_modules/@angular/material' as mat;

@mixin warning-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);

  app-warning {
    .warning-container {
      border: 0.125rem solid mat.get-color-from-palette($warn, 200);

      .warning-message, .warning-title {
        @include mat.typography-level($zonar-default-typography, body-1);
        color: mat.get-color-from-palette($primary, 900);
      }

      .warning-icon {
        color: mat.get-color-from-palette($warn, 200);
      }
    }
  }
}
