@use 'node_modules/@angular/material' as mat;

@mixin create-user-profile-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $foreground: map-get($theme, foreground);

  app-create-user-profile mat-icon {
    &.successful-creation-icon {
      color: green;
    }
    &.unsuccessful-creation-icon {
      color: red;
    }
  }

  app-create-user-profile {
    .enable-profile-title {
      color: mat.get-color-from-palette($primary, 700);
      font-size: 0.875rem;
    }

    .data-list {
      border-color: mat.get-color-from-palette($zonar-gray, 300);
    }

    .allow-line-break::first-line {
      font-weight: bold;
      font-size: larger;
    }

    .tooltip-size {
      font-size: 1.5rem;
    }

    .create-title {
      @include mat.typography-level($zonar-default-typography, subheading-1);
    }

    .mat-body {
      color: mat.get-color-from-palette($primary, 900);
      @include mat.typography-level($zonar-default-typography, body-2);
      font-weight: normal;
    }



    .data-list-description {
      font-size: 0.875rem;
    }

    a:link, a:visited {
      color: mat.get-color-from-palette($foreground, hyperlink);
    }

    .externally-managed-user {
      font-style: italic;
      opacity: 0.6;
    }
  }
}
