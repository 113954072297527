@use 'node_modules/@angular/material' as mat;

@mixin single-dropdown-panel-theme($theme){
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  .single-dropdown-panel {
    .title {
      @include mat.typography-level($zonar-default-typography, subheading-2);
      color: mat.get-color-from-palette($zonar-gray, darker);
    }

    .color-zonar-blue {
      color: mat.get-color-from-palette($foreground, edit-icon);
    }

    .description {
      @include mat.typography-level($zonar-default-typography, body-1);
      color: mat.get-color-from-palette($zonar-gray, default);
    }

    .form .form-group {
      .group-title {
        @include mat.typography-level($zonar-default-typography, body-1);
        color: mat.get-color-from-palette($zonar-gray, default);
      }

      .group-clear {
        @include mat.typography-level($zonar-default-typography, body-2);
        color: mat.get-color-from-palette($zonar-blue, default);
      }
    }
  }
}
