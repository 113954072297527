@use 'node_modules/@angular/material' as mat;

@mixin multi-tagid-dialog-theme($theme){
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  $zonar-color-critical: get-mat-color($warn, 500);
  $zonar-color-ghost: get-mat-color($primary, 300);
  $zonar-color-gray: get-mat-color($primary, default);
  $zonar-color-black: get-mat-color($primary, 900);

  mat-dialog-container#user-multi-tagid-dialog {
    padding: 0;
  }

  .color-zonar-critical {
    color: $zonar-color-critical;
  }

  .overflow-border {
    border-top-color: $zonar-color-ghost;
  }

  th.mat-header-cell, td.mat-cell {
    border-bottom-color: $zonar-color-ghost;
  }

  th.mat-header-cell {
    color: $zonar-color-gray;
  }

  td.mat-cell {
    color: $zonar-color-black;
  }
}
