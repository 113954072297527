@use "@angular/material" as mat;

@mixin role-capabilities-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  app-role-capabilities {
    .role-container {
      font-size: 0.875rem;
      border-color: mat.get-color-from-palette($zonar-gray, 300);

      .role-description {
        font-size: 0.75rem;
      }

      .division-member {
        font-size: 0.875rem;

        span {
          font-size: 0.875rem;
          font-weight: 600;
        }

        .dropdown-division {
          .mat-chip-list-wrapper {
            font-size: 1.25rem;
          }

          .more-button {
            font-size: 1rem;
            background-color: mat.get-color-from-palette($zonar-gray, A100);
            border-color: mat.get-color-from-palette($zonar-gray, 600);
          }
        }
      }
    }

    ::ng-deep .zonar-default-theme .mat-primary .mat-pseudo-checkbox-checked,
    .zonar-default-theme .mat-primary .division-member .mat-pseudo-checkbox-indeterminate {
      background: $blue;
    }
  }
}
