@use 'node_modules/@angular/material' as mat;

@mixin tree-with-checkboxes-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);

  app-tree-with-checkboxes {
    .data-list-container {
      background-color: $white;
      .data-name {
        background: mat.get-color-from-palette($zonar-gray, 200);
        font-size: 0.75rem;
        color: mat.get-color-from-palette($zonar-gray, default);
      }
    }
  }
}