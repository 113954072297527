@use 'node_modules/@angular/material' as mat;

@mixin create-asset-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $foreground: map-get($theme, foreground);

  app-create-asset {
    .create-asset-container {
      .modified-date {
        @include mat.typography-level($zonar-default-typography, caption);
        color: mat.get-color-from-palette($primary, default);
      }

      .external-managed-help-text {
        @include mat.typography-level($zonar-default-typography, caption);
        color: mat.get-color-from-palette($primary, default);

        a {
          font-weight: 700;
          color: mat.get-color-from-palette($foreground, hyperlink);
        }
      }

      zui-searchable-dropdown.form-field-empty .mat-form-field-disabled.mat-form-field-appearance-outline {
        .mat-form-field-label {
          color: mat.get-color-from-palette($foreground, base);
          font-style: italic;
          opacity: 1;
        }
      }

      mat-form-field.mat-form-field-disabled.mat-form-field-appearance-outline {
        opacity: 0.6;

        .mat-form-field-label {
          color: mat.get-color-from-palette($foreground, base);
        }
        
        &.form-field-empty {
          .mat-form-field-label {
            font-style: italic;
            opacity: 1;
          }
        }

        input:disabled {
          color: mat.get-color-from-palette($foreground, base);
          font-style: italic;
          opacity: 1;
        }

        .mat-form-field-infix mat-select.mat-select-disabled .mat-select-value {
          color: mat.get-color-from-palette($foreground, base);
          font-style: italic;
          opacity: 1;
        }
      }
      

      .cvi-description {
        @include mat.typography-level($zonar-default-typography, caption);
        color: mat.get-color-from-palette($primary, default);
      }

      app-setting-toggle mat-icon {
        color: mat.get-color-from-palette($primary, 900);
      }

      .is-hazardous-toggle {
        .mat-slide-toggle.mat-disabled {
          opacity: 0.6;
        }

        .profile-container.disable-toggle .mat-body {
          opacity: 0.6;
        }
      }

      .hazmat-tri-toggle {
        .slide-toggle .toggle-container.zui-three-states-toggle-disabled {
          opacity: 0.6;
        }

        .profile-container.disable-toggle .mat-body {
          opacity: 0.6;
        }
      }

      .background-stripes {
        .profile-container {
          background: repeating-linear-gradient(-45deg, $white, $white 1rem, $smoke 1rem, $smoke 1.5rem);
        }
      }

      .cvi-tag {
        @include mat.typography-level($zonar-default-typography, body-2);
        color: mat.get-color-from-palette($primary, default);
      }

      .divider {
        border-top-color: $ghost;
      }

      .vin-input {
        color: mat.get-color-from-palette($zonar-gray, 700);
        font-size: 0.75rem;
      }

      .icon {
        font-size: 1.125rem;
      }
    }

    .mat-form-field-appearance-outline {
      .mat-form-field-wrapper .mat-form-field-outline {
        color: mat.get-color-from-palette($primary, 500);
      }

      .mat-form-field-label {
        color: mat.get-color-from-palette($primary, default);
        font-size: 0.875rem;
      }

      .mat-form-field-infix {
        mat-select {
          .mat-select-value {
            @include mat.typography-level($zonar-default-typography, body-2);
            font-weight: 400;
          }
        }
      }

      .mat-input-element {
        font-size: 0.875rem;
      }
    }
  }

  // this prevents the icon background from being darker than the surrounding option (on hover)
  .mat-option {
    &:hover {
      :not(.mat-option-disabled) {
        .mat-icon {
          background-color: transparent;
        }
      }
    }
  }
}
