@use 'node_modules/@angular/material' as mat;

@mixin label-theme($theme) {
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  app-label {
    .label {
      @include mat.typography-level($zonar-default-typography, caption);
      border-radius: 3px;
      font-weight: 700;
      padding: 0.25rem 0.5rem;
      width: fit-content;
    }
  
    .information {
      color: mat.get-color-from-palette($foreground, base);
      background-color: $selected-blue;
    }
  
    .error {
      color: mat.get-color-from-palette($foreground, text-flat-button);
      background-color: $critical;
    }
  
    .success {
      color: mat.get-color-from-palette($foreground, text-flat-button);
      background-color: $pass;
    }
  
    .warning {
      color: mat.get-color-from-palette($foreground, base);
      background-color: $warn;
    }
  
    .general {
      color: mat.get-color-from-palette($foreground, text-flat-button);
      background-color: mat.get-color-from-palette($foreground, text-breadcrumb);
    }
  }
}
