@use "@angular/material" as mat;
@use "sass:meta";

@import "@zonar-ui/material/zonar-ui-material.theme";

@mixin ss-common-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  $zonar-color-ghost: get-mat-color($primary, 300);
  $zonar-color-steel: mat.get-color-from-palette($foreground, icon-steel);
  $zonar-color-dark-blue: mat.get-color-from-palette($accent, darker);
  $zonar-color-blue: mat.get-color-from-palette($background, flat-button);
  $zonar-color-white: mat.get-color-from-palette($foreground, text-flat-button);
  $zonar-color-critical: get-mat-color($warn, 500);
  $zonar-color-black: mat.get-color-from-palette($foreground, base);

  $zonar-color-hightlight-blue: #E6F2F8;

  ::-webkit-scrollbar {
    width: 0.313rem;
    height: 0.313rem;
    border-radius: 0.188rem;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: $conti-6;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: $silver;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: $silver;
  }

  // fix for material buttons
  .mat-button-wrapper {
    padding: 0;
    margin: 0;
    line-height: unset;
  }

  .mat-flat-button,
  .mat-raised-button {
    background-color: mat.get-color-from-palette($background, flat-button);
    color: mat.get-color-from-palette($foreground, text-flat-button);

    &:hover {
      background-color: mat.get-color-from-palette($accent, darker);
    }
  }

  // ink bar tab color
  .mat-tab-group .mat-tab-header .mat-tab-label-container .mat-tab-list .mat-ink-bar {
    background-color: mat.get-color-from-palette($background, flat-button);
  }

  // text information color
  .text-info-color {
    color: mat.get-color-from-palette($foreground, text-info);
  }

  .mat-progress-spinner circle,
  .mat-spinner circle {
    stroke: mat.get-color-from-palette($background, flat-button);
  }

  .mat-focused .mat-form-field-required-marker,
  .mat-form-field-required-marker {
    color: $critical;
  }

  // This should be applied globally for required field.
  .required:after {
    content: " *";
    color: $critical;
  }

  .card {
    background-color: mat.get-color-from-palette($background, card);
    padding: 1.5rem;
    margin-bottom: 1.5rem;
    border-radius: 0.1875rem;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.30), 0px 2px 6px 2px rgba(0, 0, 0, 0.15);
  }

  .card-mobile {
    background-color: mat.get-color-from-palette($background, card);
    padding: 1rem;
    margin-bottom: 1.5rem;
    border-radius: 0.1875rem;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.30), 0px 2px 6px 2px rgba(0, 0, 0, 0.15);
  }

  .mobile-list-card {
    padding: 0.75rem;
  }

  // Add asterix to required input label when disabled.
  .required-disabled:after {
    content: " *";
    color: mat.get-color-from-palette($foreground, icon-steel);
  }

  .label-asterisk:after {
    content: " *";
  }

  .mat-form-field-appearance-outline {
    .mat-form-field-outline-start {
      border: 1px solid mat.get-color-from-palette($foreground, icon-steel);
      border-right: 0px;
    }
    .mat-form-field-outline-gap {
      border-bottom: 1px solid mat.get-color-from-palette($foreground, icon-steel);
      border-top: 1px solid mat.get-color-from-palette($foreground, icon-steel);
    }
    .mat-form-field-outline-end {
      border: 1px solid mat.get-color-from-palette($foreground, icon-steel);
      border-left: 0px;
    }
  }

  .error-common-input {
    opacity: 30%;

    mat-label {
      color: $zonar-color-critical;
    }

    .mat-form-field-flex .mat-form-field-outline {
      .mat-form-field-outline-start {
        border: 1px solid $zonar-color-critical;
        border-right: 0px;
      }
      .mat-form-field-outline-gap {
        border-bottom: 1px solid $zonar-color-critical;
        border-top: 1px solid $zonar-color-critical;
      }
      .mat-form-field-outline-end {
        border: 1px solid $zonar-color-critical;
        border-left: 0px;
      }
    }
  }

  mat-header-row.mat-header-row {
    z-index: 10 !important;
  }

  zui-table-header header {
    section.right-content-container button {
      height: 2rem;
    }

    .table-title-container {
      color: $zonar-color-black;
    }
  }

  zui-table {
    .table-container {
      overflow-x: auto;
      width: 100%;
    }

    .mat-header-row {
      min-height: 2.5rem;
    }

    .mat-header-cell {
      width: auto;
      max-width: none;
      overflow: visible;
    }
  }

  .divider-color-light {
    border-top-color: #CDCDCD;
  }

  .margin-vertical-24px {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }

  .hyperlink-zonar-blue {
    color: mat.get-color-from-palette($foreground, hyperlink);
  }

  .color-zonar-black {
    color: $zonar-color-black;
  }

  // Common selection controls
  mat-radio-button.mat-radio-button .mat-radio-label-content {
    @include mat.typography-level($zonar-default-typography, body-1);
    line-height: 1.5rem;
    color: $zonar-color-black;
    padding-left: 4px;
  }

  // font 36px, weight 300, line-height 48px
  .typography-desktop-headline-1 {
    @include mat.typography-level($zonar-default-typography, display-1);
    line-height: 3rem;
  }

  // font 28px, weight 400, line-height 40px
  .typography-desktop-headline-3 {
    @include mat.typography-level($zonar-default-typography, subheading-2);
    line-height: 2.5rem;
  }

  // font 16px, weight 400, line-height 24px
  .typography-desktop-body-1 {
    @include mat.typography-level($zonar-default-typography, body-1);
    line-height: 1.5rem;
  }

  // font 16px, weight 600, line-height 18px
  .typography-desktop-table-header {
    @include mat.typography-level($zonar-default-typography, body-1);
    line-height: 1.125rem;
    font-weight: 600;
  }

  // font 14px, weight 700, line-height 24px
  .typography-body-2 {
    @include mat.typography-level($zonar-default-typography, body-2);
    line-height: 1.5rem;
  }
}
