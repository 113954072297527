@use 'node_modules/@angular/material' as mat;
@mixin access-roles-privileges-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  app-access-roles-privileges {
    .app-name {
      color: mat.get-color-from-palette($primary, 900);
      font-size: 0.875rem;
    }

    .container {
      .title-border {
        border-color: $ghost;

        .app-warn {
          color: $critical;
          @include mat.typography-level($zonar-default-typography, caption);
        }
      }
    }
  }
}
