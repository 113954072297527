@use 'node_modules/@angular/material' as mat;

@mixin company-info-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  app-company-info {
    .company-info {
      background-color: $white;
    }
  }
}