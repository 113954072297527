@use 'node_modules/@angular/material' as mat;

@mixin manage-asset-property-theme($theme){
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  $zonar-color-black: mat.get-color-from-palette($foreground, base);

  .header {
    .header-backlink {
      @include mat.typography-level($zonar-default-typography, body-2);
      color: mat.get-color-from-palette($zonar-blue, default);
      font-weight: 400;
    }

    .content-button {
      @include mat.typography-level($zonar-default-typography, body-1);
      font-weight: 700;

      &.mat-stroked-button:not(.mat-button-disabled, .sidenav-toggle) {
        color: mat.get-color-from-palette($zonar-gray, 50);
        background-color: mat.get-color-from-palette($zonar-blue, default);
      }
    }

    .description {
      @include mat.typography-level($zonar-default-typography, body-1);
      color: mat.get-color-from-palette($zonar-gray, default);
    }
  }

  .color-black {
    color: $zonar-color-black;
  }
}
