@use 'node_modules/@angular/material' as mat;

@mixin confirm-message-dialog-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);

  app-confirm-message-dialog {
    .confirm-message-dialog {
      .title {
        @include mat.typography-level($zonar-default-typography, subheading-1);
        color: mat.get-color-from-palette($primary, 900);
        font-size: 20px;
      }

      .messages {
        @include mat.typography-level($zonar-default-typography, body-1);
        color: mat.get-color-from-palette($primary, 900);
      }

      /* buttons */
      .stroked-button {
        @include mat.typography-level($zonar-default-typography, body-1);
        color: mat.get-color-from-palette($zonar-blue, default);
        background-color: transparent;
      }

      .raised-button {
        @include mat.typography-level($zonar-default-typography, body-1);
        color: mat.get-color-from-palette($zonar-gray, 50);
        background-color: mat.get-color-from-palette($zonar-blue, default);
      }
    }
  }
}
