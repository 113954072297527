@use 'node_modules/@angular/material' as mat;

@mixin company-theme($theme){
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  mat-icon {
    max-height: 1em;
    margin-right: 0.25rem;
    cursor: pointer;
  }
  // To handle icon in tables.
  .material-icons {
    cursor: pointer;
  }

}
