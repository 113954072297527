@use 'node_modules/@angular/material' as mat;

@mixin bulk-user-import-requirements-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $foreground: map-get($theme, foreground);

  app-bulk-user-import-requirements {
    .bulk-import-container {
      .guide-title {
        @include mat.typography-level($zonar-default-typography, display-1);
        color: mat.get-color-from-palette($primary, 900);
      }

      .guide-description {
        @include mat.typography-level($zonar-default-typography, body-1);
        color: mat.get-color-from-palette($primary, 800);
      }
    }

    a:link,
    a:visited {
      color: mat.get-color-from-palette($foreground, hyperlink);
    }
  }
}
