@use 'node_modules/@angular/material' as mat;

@mixin policy-list-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  $zonar-color-blue: mat.get-color-from-palette($background, flat-button);
  $zonar-color-steel: mat.get-color-from-palette($background, border-stroked-button);

  app-policy-list {
    .table-container .mat-table {
      mat-cell.mat-column-edit span {
        font-weight: 600;
        font-size: 0.875rem;
        color: $zonar-color-blue;
        border: 0.063rem solid $zonar-color-steel;
      }
    }

    .policy-list {
      .custom-policy-name {
        .policy-name {
          @include mat.typography-level($zonar-default-typography, body-1);
        }

        .name-with-link {
          color: mat.get-color-from-palette($accent, default);
        }
      }
    }
  }

}