@use '@angular/material' as mat;

@import '@zonar-ui/material/zonar-ui-material.theme.scss';
@import '@zonar-ui/sidenav/zonar-ui-sidenav.theme.scss';
@import '@zonar-ui/table/zonar-ui-table.theme';
@import '@zonar-ui/notifications/zonar-ui-notifications.theme';
@import '@zonar-ui/three-states-toggle/zonar-ui-three-states-toggle.theme';
@import '@zonar-ui/footer/zonar-ui-footer.theme.scss';
@import 'flag-icon-css/css/flag-icons.min.css';
@import '@zonar-ui/core/zonar-ui-core.theme.scss';
@import '@zonar-ui/filter/zonar-ui-filter.theme.scss';
@import '../app/modules/company/pages/create-company/create-company.theme';
@import '../app/modules/company/company.theme';
@import '../app/modules/company/pages/division-view/division-view.theme.scss';
@import '../app/modules/company/pages/create-division/create-division.theme.scss';
@import '../app/modules/zonar-ui-breadcrumb/zonar-ui-breadcrumb.theme';
@import '../app/shared/components/address-form/address-form.theme';
@import '../app/shared/dialogs/caution-popup/caution-popup.theme.scss';
@import '../app/modules/user/components/create-user-group-policy/create-user-group-policy.theme';
@import '../app/modules/user/components/create-user-profile/create-user-profile.theme';
@import '../app/modules/user/components/create-user-form/create-user-form.theme';
@import '../app/modules/user/pages/user-list-container/user-list-container.theme';
@import '../app/modules/user/pages/bulk-edit-users/bulk-edit-users.theme';
@import '../app/shared/components/list-management/list-management.theme';
@import '../app/modules/asset/pages/create-asset/create-asset.theme.scss';
@import '../app/shared/dialogs/division-dialog/division-dialog.theme.scss';
@import '../app/shared/components/page-not-found/page-not-found.theme.scss';
@import '../app/shared/components/warning/warning.component.theme.scss';
@import '../app/modules/user/components/access-roles-privileges/access-roles-privileges.theme.scss';
@import '../app/shared/dialogs/announcement-dialog/announcement-dialog.theme.scss';
@import '../app/shared/dialogs/generate-password-dialog/generate-password-dialog.theme.scss';
@import '../app/shared/dialogs/preview-dialog/preview-dialog.theme.scss';
@import '../app/shared/dialogs/bulk-asset-import/bulk-asset-import.theme.scss';
@import '../app/shared/dialogs/bulk-user-import/bulk-user-import.theme.scss';
@import '../app/shared/dialogs/bulk-edit-result/bulk-edit-result.theme.scss';
@import '../app/shared/components/file-upload/file-upload.theme.scss';
@import '../app/shared/components/chip-list/chip-list.theme.scss';
@import '../app/shared/components/bulk-user-import-requirements/bulk-user-import-requirements.theme.scss';
@import '../app/shared/components/bulk-asset-import-requirements/bulk-asset-import-requirements.theme.scss';
@import '../app/shared/components/import-guide/import-guide.theme.scss';
@import '../app/shared/components/search/search.theme.scss';
@import '../app/shared/components/checkbox-list/checkbox-list.theme.scss';
@import '../app/shared/components/role-capabilities/role-capabilities.theme.scss';
@import '../app/shared/dialogs/alert-dialog/alert-dialog.theme.scss';
@import '../app/shared/dialogs/confirm-dialog/confirm-dialog.theme.scss';
@import '../app/shared/components/tree-with-checkboxes/tree-with-checkboxes.theme.scss';
@import '../app/shared/components/nested-division/nested-division.theme.scss';
@import '../app/shared/components/multi-states-toggle/multi-states-toggle.theme.scss';
@import '../app/shared/dialogs/bulk-user-preview-dialog/bulk-user-preview-dialog.theme.scss';
@import '../app/shared/components/create-edit-with-tree/create-edit-with-tree.theme.scss';
@import '../app/shared/components/create-edit-with-tree-mobile/create-edit-with-tree-mobile.theme.scss';
@import '../app/modules/policy/components/policy-list/policy-list.theme.scss';
@import '../app/modules/user/components/user-list-group-policy/user-list-group-policy.theme.scss';
@import 'material-icons/iconfont/material-icons.scss';
@import '../app/shared/components/card/card.theme.scss';
@import '../app/shared/components/card-list/card-list.theme.scss';
@import '../app/shared/dialogs/migration-indeterminate-dialog/migration-indeterminate-dialog.theme.scss';
@import '../app/shared/components/snack-banner/snack-banner.theme.scss';
@import '../app/modules/asset/pages/manage-asset-property/manage-asset-property.theme.scss';
@import '../app/modules/asset/components/asset-property-list/asset-property-list.theme.scss';
@import '../app/shared/dialogs/create-edit-custom-property-dialog/create-edit-custom-property-dialog.theme.scss';
@import '../app/modules/asset/pages/edit-asset-property/edit-asset-property.theme.scss';
@import '../app/shared/components/single-dropdown-panel/single-dropdown-panel.theme.scss';
@import '../app/modules/asset/pages/bulk-edit-asset-property/bulk-edit-asset-property.theme.scss';
@import '../app/shared/dialogs/confirm-message-dialog/confirm-message-dialog.theme.scss';
@import '../app/shared/components/mobile-drawer/mobile-drawer.component.theme.scss';
@import '../app/shared/components/single-grid-panel/single-grid-panel.theme.scss';
@import '../app/shared/dialogs/information-dialog/information-dialog.theme.scss';
@import '../app/modules/company/components/company-info/company-info.theme.scss';
@import '../app/modules/company/components/company-products/company-products.theme.scss';
@import '../app/modules/company/pages/company-management/company-management.theme.scss';
@import '@zonar-ui/asset-properties/zonar-ui-asset-properties.theme';
@import '../app/shared/components/label/label.component.theme.scss';
@import '../app/shared/dialogs/multi-tagid-dialog/multi-tagid-dialog.theme.scss';

@import './common.theme';

@mixin apply-zonar-theme($theme) {
  @include mat.all-component-themes($theme);
  @include zonar-ui-footer-component-theme($theme);
  @include zonar-ui-core-theme($theme);
  @include zonar-ui-three-states-toggle-theme($theme);
  @include zonar-ui-table-theme($theme);
  @include zonar-ui-sidenav-theme($theme);
  @include zonar-ui-filter-theme($theme);
  @include zonar-ui-asset-properties-theme($theme);
  @include company-theme($theme);
  @include app-user-list-container($theme);
  @include division-view-theme($theme);
  @include create-company-theme($theme);
  @include zonar-ui-notifications-theme($theme);
  @include create-division-theme($theme);
  @include create-user-group-policy-theme($theme);
  @include create-user-profile-theme($theme);
  @include create-user-form-theme($theme);
  @include bulk-edit-users-theme($theme);
  @include bread-crumb-theme($theme);
  @include address-form-theme($theme);
  @include app-caution-popup-theme($theme);
  @include list-management-theme($theme);
  @include create-asset-theme($theme);
  @include division-dialog-theme($theme);
  @include announcement-dialog-theme($theme);
  @include generate-password-dialog-theme($theme);
  @include page-not-found-theme($theme);
  @include warning-theme($theme);
  @include preview-dialog-theme($theme);
  @include bulk-asset-import-dialog-theme($theme);
  @include app-bulk-edit-result-theme($theme);
  @include file-upload-theme($theme);
  @include ss-common-theme($theme);
  @include access-roles-privileges-theme($theme);
  @include bulk-user-import-requirements-theme($theme);
  @include bulk-asset-import-requirements-theme($theme);
  @include import-guide-theme($theme);
  @include chip-list-theme($theme);
  @include search-theme($theme);
  @include checkbox-list-theme($theme);
  @include role-capabilities-theme($theme);
  @include alert-dialog-theme($theme);
  @include confirm-dialog-theme($theme);
  @include tree-with-checkboxes-theme($theme);
  @include nested-division-theme($theme);
  @include bulk-user-import-dialog-theme($theme);
  @include multi-states-toggle-theme($theme);
  @include bulk-user-preview-dialog-theme($theme);
  @include create-edit-with-tree-theme($theme);
  @include policy-list-theme($theme);
  @include user-list-group-policy-theme($theme);
  @include card-theme($theme);
  @include card-list-theme($theme);
  @include migration-indeterminate-dialog-theme($theme);
  @include snack-banner-theme($theme);
  @include asset-property-list-theme($theme);
  @include manage-asset-property-theme($theme);
  @include create-edit-custom-property-dialog-theme($theme);
  @include edit-asset-property-theme($theme);
  @include single-dropdown-panel-theme($theme);
  @include bulk-edit-asset-property-theme($theme);
  @include confirm-message-dialog-theme($theme);
  @include mobile-drawer-theme($theme);
  @include single-grid-panel-theme($theme);
  @include information-dialog-theme($theme);
  @include company-info-theme($theme);
  @include company-products-theme($theme);
  @include company-management-theme($theme);
  @include label-theme($theme);
  @include multi-tagid-dialog-theme($theme);
  @include create-edit-with-tree-mobile-theme($theme);
}

.zonar-default-theme {
  @include apply-zonar-theme($zonar-default-theme);
  background-color: #f3f4f5;
}

.zonar-dark-theme {
  @include apply-zonar-theme($zonar-dark-theme);
  background-color: black;
}

.zonar-conti-theme {
  @include apply-zonar-theme($zonar-conti-theme);
  background-color: #f0f0f0;
}
