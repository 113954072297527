@use 'node_modules/@angular/material' as mat;

@mixin create-edit-with-tree-mobile-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $foreground: map-get($theme, foreground);
  $background: map-get($theme, background);

  $zonar-color-gunmental: mat.get-color-from-palette($primary, darker);
  $zonar-color-hightlight-blue: #E6F2F8;
  $zonar-color-ghost: get-mat-color($primary, 300);

  app-create-edit-with-tree-mobile {
    .policy-type-filter-container {
      border-bottom: 1px solid $zonar-color-ghost;

      .policy-type-filter-title {
        color: $zonar-color-gunmental;
      }

      .policy-type-filter-message-container {
        background-color: $zonar-color-hightlight-blue;
      }
    }

    .create-edit-tree-container app-search .search-container {
      .mat-form-field {
        background-color: mat.get-color-from-palette($background, stroked-button);
      }

      .mat-form-field-appearance-outline .mat-form-field-outline,
      .zonar-default-theme .mat-form-field-appearance-outline .mat-form-field-outline-thick {
        color: mat.get-color-from-palette($background, stroked-button);
      }
    }

    .title {
      @include mat.typography-level($zonar-default-typography, headline);
    }

    .role-empty-error-message {
      @include mat.typography-level($zonar-default-typography, caption);
      color: mat.get-color-from-palette($warn, default);
      line-height: 1rem;
    }

    .warning-container {
      border: 0.125rem solid mat.get-color-from-palette($warn, 200);

      .warning-text {
        @include mat.typography-level($zonar-default-typography, body-1);
        color: mat.get-color-from-palette($primary, 900);
      }

      .warning-icon {
        color: mat.get-color-from-palette($warn, 200);
      }
    }

    .create-edit-tree-mobile-container {
      .user-count {
        color: $blue;
      }

      .division-container {
        border-color: $silver;
      }

      .duplicated-error-message {
        @include mat.typography-level($zonar-default-typography, caption);
      }
    }

    .create-button {
      color: $blue;
    }

    .text-description>span {
      @include mat.typography-level($zonar-default-typography, caption);
    }

    .button-all {
      @include mat.typography-level($zonar-default-typography, body-1);
      color: mat.get-color-from-palette($accent, default);
      font-weight: 700;
    }

    .divider {
      border-left-color: $steel;
    }
  }
}
