@use 'node_modules/@angular/material' as mat;

@mixin alert-dialog-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);

  app-alert-dialog {
    .alert-dialog {
      .head-icon {
        color: mat.get-color-from-palette($zonar-gray, 900);
        font-size: 5rem;
      }

      .title {
        @include mat.typography-level($zonar-default-typography, subheading-1);
      }

      .messages {
        @include mat.typography-level($zonar-default-typography, body-1);
      }

      .dialog-button {
        button {
          @include mat.typography-level($zonar-default-typography, body-1);
        }
      }
    }
  }
}
