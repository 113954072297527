@use 'node_modules/@angular/material' as mat;

@mixin nested-division-theme($theme) {
  $primary: map-get($theme, primary);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $accent: map-get($theme, accent);

  app-nested-division {
    .color-active {
      color: mat.get-color-from-palette($foreground, icon-pass);
    }

    .color-inactive {
      color: mat.get-color-from-palette($foreground, icon-steel);
    }

    .edit-button-color {
      color: mat.get-color-from-palette($foreground, edit-icon);
    }

    .highlight-row {
      background-color: $selected-blue;
    }

  }
}