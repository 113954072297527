@use 'node_modules/@angular/material' as mat;

@mixin create-edit-custom-property-dialog-theme($theme){
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  .create-edit-custom-property-dialog {
    .dialog-title span {
      @include mat.typography-level($zonar-default-typography, subheading-1);
      color: mat.get-color-from-palette($zonar-gray, 900);
    }

    .dialog-form {
      span.form-field-label {
        @include mat.typography-level($zonar-default-typography, body-1);
        color: mat.get-color-from-palette($zonar-gray, 900);
        font-weight: 700;
      }
    }

    /* buttons */
    .stroked-button {
      @include mat.typography-level($zonar-default-typography, body-1);
      color: mat.get-color-from-palette($zonar-blue, default);
      background-color: mat.get-color-from-palette($zonar-gray, 50);
    }

    .raised-button {
      @include mat.typography-level($zonar-default-typography, body-1);
      color: mat.get-color-from-palette($zonar-gray, 50);
      background-color: mat.get-color-from-palette($zonar-blue, default);
    }
  }
}
