@use "@angular/material" as mat;

@mixin generate-password-dialog-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $foreground: map-get($theme, foreground);

  app-generate-password-dialog {
    .generate-password-container {
      color: mat.get-color-from-palette($primary, 900);
      .dialog-title {
        @include mat.typography-level($zonar-default-typography, subheading-1);
      }

      .description {
        @include mat.typography-level($zonar-default-typography, caption);

        .title-description {
          @include mat.typography-level($zonar-default-typography, body-2);
        }

        .body-description {
          font-weight: 400;
        }
      }

      .dialog-body {
        @include mat.typography-level($zonar-default-typography, body-1);
      }

      .dialog-body-text {
        @include mat.typography-level($zonar-default-typography, title);
        color: mat.get-color-from-palette($foreground, icon-pass);
      }
    }
  }
}
