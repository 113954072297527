@mixin snack-banner-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  // .mat-snackbar-container-snack-banner {
  //   margin-bottom: 40px;
  //   padding: 0;
  //   background-color: white;
  //   min-height: 100%;
  // }

  .snack-banner-container {
    border-width: 3px;
    border-style: solid;
    border-radius: 3px;
    color: black;

    &.snack-banner-info {
      border-color: get-mat-color($zonar-blue, darker);
    }

    &.snack-banner-success {
      border-color: $pass;
    }

    &.snack-banner-warning {
      border-color: get-mat-color($default-warn, 200);
    }

    &.snack-banner-error {
      border-color: get-mat-color($default-warn, darker);
    }

    .snack-banner-icon-container {
      color: $light-primary-text;

      &.snack-banner-info {
        background-color: get-mat-color($zonar-blue, darker);
      }

      &.snack-banner-success {
        background-color: $pass;
      }

      &.snack-banner-warning {
        background-color: get-mat-color($default-warn, 200);
      }

      &.snack-banner-error {
        background-color: get-mat-color($default-warn, darker);
      }

    }

    .snack-banner-content {
      background-color: get-mat-color($background, card);
      p {
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .snack-banner-title {
        font-weight: bold;
      }
    }

    .action-button-containter {
      background-color: get-mat-color($background, card);
    }

    button {
      display: flex;
      justify-content: center;
      align-items: center;

      span {
        display: inline-block;
        white-space: pre-wrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}
