@use 'node_modules/@angular/material' as mat;
@mixin multi-states-toggle-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  app-multi-states-toggle {
    .toggle-name {
      color: mat.get-color-from-palette($primary, 900);
      font-size: 0.875rem;
    }

    .container {
      .title-border {
        border-color: $ghost;

        .toggle-warn {
          color: $critical;
          @include mat.typography-level($zonar-default-typography, caption);
        }
      }
    }
  }
}
