@use "@angular/material" as mat;

@mixin file-upload-theme($theme) {
  $foreground: map-get($theme, foreground);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);

  app-file-upload {
    .browse-text {
      color: mat.get-color-from-palette($foreground, hyperlink);
    }

    .file-upload-container {
      border: dashed 1px mat.get-color-from-palette($foreground, hyperlink);
    }

    .icon-success {
      color: mat.get-color-from-palette($foreground, icon-pass);
    }

    .icon-warning {
      color: mat.get-color-from-palette($warn, 200);
    }

    .icon-error {
      color: mat.get-color-from-palette($warn, darker);
    }
  }
}
