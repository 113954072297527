@use "@angular/material" as mat;

@mixin import-guide-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);

  app-import-guide {
    .requirement-field-container {
      color: mat.get-color-from-palette($primary, 900);

      .title {
        @include mat.typography-level($zonar-default-typography, subheading-1);
        color: mat.get-color-from-palette($primary, 900);
      }

      .fields-container {
        background-color: $white;

        .field-header {
          @include mat.typography-level($zonar-default-typography, body-2);
          border-bottom: 0.125rem solid mat.get-color-from-palette($primary, 300);
          color: $gun-metal;
        }
        .field-body {
          @include mat.typography-level($zonar-default-typography, body-1);
          border-bottom: 0.063rem solid mat.get-color-from-palette($primary, 300);
          color: mat.get-color-from-palette($primary, 900);
        }
      }
    }
  }
}
