@use 'node_modules/@angular/material' as mat;

@mixin bread-crumb-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  .breadcrumb-container {
    margin-top: 0;
    background: none;
    font-size: 0.8em;
    display: flex;
    align-items: center;
    height: 3rem;
    padding: 0;
    border-bottom: 0.1em solid mat.get-color-from-palette($primary, lighter);
    li {
      @include mat.typography-level($zonar-default-typography, caption);
      list-style: none;
      margin: 0 0.437rem 0 0.125rem;
    }
  }

  .color-default-icon {
    color: mat.get-color-from-palette($primary, 900);
    margin-right: 0.375rem;
  }
  .color-arrow-icon {
    color: mat.get-color-from-palette($foreground, icon-breadcrumb);
  }
  .cursor-icon {
    cursor: pointer;
    font-size: 1.125rem;
    width: 1.125rem;
  }

  .none-cursor-icon {
    width: 1.125rem;
    font-size: 1.125rem;
    cursor: unset;
  }

  .breadcrumb-router {
    color: mat.get-color-from-palette($foreground, text-breadcrumb);
    font-family: "Open Sans";
    font-size: 0.95em;
    letter-spacing: 0;
    line-height: 1em;
    text-decoration: none;

    &.active {
      color: mat.get-color-from-palette($foreground, text-breadcrumb-active);
      font-weight: 600;
      cursor: default;
    }

    &.disabled {
      pointer-events: none;
      cursor: default;
    }
  }
}
