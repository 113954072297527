@use 'node_modules/@angular/material' as mat;

@mixin bulk-edit-asset-property-theme($theme){
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  .bulk-edit-asset {
    .header {
      .header-backlink {
        @include mat.typography-level($zonar-default-typography, body-2);
        color: mat.get-color-from-palette($zonar-blue, default);
        font-weight: 400;
      }

      .header-title {
        @include mat.typography-level($zonar-default-typography, display-1);
        color: mat.get-color-from-palette($zonar-gray, 900);
      }
    }

    .stroked-button {
      @include mat.typography-level($zonar-default-typography, body-1);
      color: mat.get-color-from-palette($zonar-blue, default);
      background-color: transparent;
    }

    .raised-button {
      @include mat.typography-level($zonar-default-typography, body-1);
      color: mat.get-color-from-palette($zonar-gray, 50);
      background-color: mat.get-color-from-palette($zonar-blue, default);
    }
  }
}
