@use 'node_modules/@angular/material' as mat;

@mixin chip-list-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);

  app-chip-list {
    .mat-chip.mat-standard-chip .mat-chip-remove.mat-icon.standard-icon-color {
      color: $conti-6;
    }

    .mat-chip.mat-standard-chip .mat-chip-remove.mat-icon.admin-icon-color {
      color: $conti-3;
    }

    .mat-chip.mat-standard-chip .mat-chip-remove.mat-icon.managed-icon-color {
      color: $conti-6;
    }

    .mat-chip.mat-standard-chip.more-btn,
    .mat-chip.mat-standard-chip.less-btn {
      background-color: $white;
      border-color: $slate;
    }

    ::-webkit-scrollbar-thumb {
      background: $steel;
    }

    .chip-list .mat-chip-list {
      .standard-user-color {
        // The color not existed on Material PL
        background-color: #969696;
      }

      .managed-user-color {
        background-color: $conti-7;
      }

      .admin-user-color {
        background-color: $silver;
      }

      .standard-icon-color,
      .managed-icon-color {
        color: $conti-6;
      }

      .admin-icon-color {
        color: $conti-1;
      }
    }
  }
}
