@use 'node_modules/@angular/material' as mat;

@mixin create-user-form-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $foreground: map-get($theme, foreground);
  $background: map-get($theme, background);

  app-create-user-form {
    .tooltip-icon {
      color: mat.get-color-from-palette($background, flat-button);
    }

    .page-title {
      @include mat.typography-level($zonar-default-typography, headline);
    }

    .button-email,
    .button-add-group {
      @include mat.typography-level($zonar-default-typography, body-1);
    }

    .section-title {
      @include mat.typography-level($zonar-default-typography, subheading-1);
    }

    .mat-form-field-appearance-outline {
      .mat-form-field-wrapper .mat-form-field-outline {
        color: mat.get-color-from-palette($primary, 500);
      }

      .mat-form-field-label {
        color: mat.get-color-from-palette($primary, default);
        font-size: 0.875rem;
      }

      .mat-form-field-infix {
        mat-select .mat-select-arrow-wrapper {
          transform: translateY(5%);
        }
      }
    }

    .resend-email {
      color: mat.get-color-from-palette($accent, 400);
    }

    .sent-email {
      color: $pass;
    }

    .generate-button::before {
      background-color: mat.get-color-from-palette($foreground, text-stroked-button);
    }

    .allow-line-break::first-line {
      font-weight: bold;
      font-size: larger;
    }

    .tooltip-size {
      font-size: 1.5rem;
    }

    .common-input {
      font-size: 0.875rem;
      color: mat.get-color-from-palette($primary, default);
    }

    .create-title {
      @include mat.typography-level($zonar-default-typography, subheading-1);
    }

    .generate-button-label {
      color: mat.get-color-from-palette($primary, 900);
      @include mat.typography-level($zonar-default-typography, caption);
    }

    .generate-button::before {
      background-color: mat.get-color-from-palette($foreground, text-stroked-button);
    }

    .generate-button-label {
      color: mat.get-color-from-palette($primary, 900);
      @include mat.typography-level($zonar-default-typography, caption);
    }

    .externally-managed-url {
      @include mat.typography-level($zonar-default-typography, body-2);
    }

    .externally-managed-user {
      font-style: italic;
      opacity: 0.6;
    }
  }
}
