@use 'node_modules/@angular/material' as mat;

@mixin create-division-theme($theme){
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);

  app-create-division .mat-form-field-appearance-outline {
    .create-title {
      @include mat.typography-level($zonar-default-typography, subheading-1);
    }

    .mat-form-field-wrapper .mat-form-field-outline {
      color: mat.get-color-from-palette($primary, 500);
    }

    .mat-form-field-label {
      color: mat.get-color-from-palette($primary, 700);
      font-size: 0.875rem;
    }

    .button-container {
      button {
        font-weight: 700;
      }
    }
  }

  app-create-division .externally-managed-url {
    @include mat.typography-level($zonar-default-typography, body-2);
  }

  app-create-division .externally-managed-division .mat-form-field.mat-form-field-disabled {
    font-style: italic;
    opacity: 0.6;
  }
}
