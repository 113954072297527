@use 'node_modules/@angular/material' as mat;

@mixin migration-indeterminate-dialog-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $foreground: map-get($theme, foreground);
  $background: map-get($theme, background);

  .migration-indeterminate-dialog {
    .migration-indeterminate-title {
      @include mat.typography-level($zonar-default-typography, subheading-1);
      color: mat.get-color-from-palette($zonar-gray, 800);
      font-weight: 600;
    }

    .migration-indeterminate-description {
      @include mat.typography-level($zonar-default-typography, body-1);
      color: mat.get-color-from-palette($foreground, base);
      font-weight: 400;
    }

    .migration-indeterminate-progress {
      .icon-area {
        .upload-icon {
          color: mat.get-color-from-palette($foreground, icon-steel);
        }
      }

      .progress-area {
        .progress-text {
          @include mat.typography-level($zonar-default-typography, body-1);
          color: mat.get-color-from-palette($foreground, base);
          font-weight: 400;
        }

        .mat-progress-bar-background.mat-progress-bar-element {
          fill: mat.get-color-from-palette($background, dialog);
        }

        .mat-progress-bar-buffer.mat-progress-bar-element {
          background-color: mat.get-color-from-palette($zonar-blue, 200);
          color: mat.get-color-from-palette($zonar-blue, 200);
        }

        .mat-progress-bar-secondary.mat-progress-bar-fill.mat-progress-bar-element {
          color: mat.get-color-from-palette($foreground, hyperlink);
        }

        .mat-progress-bar-secondary.mat-progress-bar-fill::after {
          background-color: mat.get-color-from-palette($foreground, hyperlink);
        }

        .mat-progress-bar-primary.mat-progress-bar-fill.mat-progress-bar-element {
          color: mat.get-color-from-palette($foreground, hyperlink);
        }

        .mat-progress-bar-primary.mat-progress-bar-fill::after {
          background-color: mat.get-color-from-palette($foreground, hyperlink);
        }
      }
    }
  }
}
