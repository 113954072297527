@use 'node_modules/@angular/material' as mat;

@mixin app-caution-popup-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);

  @include create-company-theme($theme);

  app-caution-popup {
    .mat-form-field-appearance-outline {
      .mat-form-field-wrapper .mat-form-field-outline {
        color: mat.get-color-from-palette($primary, 500);
      }

      .mat-form-field-label {
        color: mat.get-color-from-palette($primary, 700);
        font-size: 0.875rem;
      }
    }

    .container {
      .title {
        font-size: 1.5rem;
        line-height: 2rem;
        color: $critical;
      }

      .input-container {
        font-size: 1rem;
        color: $black;

        mat-label {
          font-size: 1.125rem;
        }
      }

      .pop-up-button-container {
        button {
          @include mat.typography-level($zonar-default-typography, body-1);
          font-weight: bold;
        }
      }
    }
  }
}
