@use 'node_modules/@angular/material' as mat;

@mixin address-form-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);

  app-address-form {
    .mat-form-field-appearance-outline {
      .mat-form-field-wrapper {
        .mat-form-field-outline {
          color: mat.get-color-from-palette($primary, 500);
        }
      }

      .mat-form-field-label {
        color: mat.get-color-from-palette($primary, default);
        font-size: 0.875rem;
      }

      .mat-form-field-infix {
        mat-select {
          .mat-select-value {
            font-size: 0.875rem;
            color: mat.get-color-from-palette($zonar-gray, default);
          }
        }
      }

      .mat-input-element {
        color: $gray;
        font-size: 0.875rem;
      }
    }

    .mat-form-field-appearance-legacy {
      .mat-form-field-label {
        color: mat.get-color-from-palette($primary, default);
        font-size: 0.875rem;
      }

      .mat-form-field-infix {
        border: 0.1rem solid $steel;
      }

      .mat-form-field-underline {
        background-color: unset;
      }

      mat-option {
        font-size: 0.875rem;
      }
    }

    .dropdown-list {
      font-size: 0.875rem;
    }
  }

}
