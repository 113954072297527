@use 'node_modules/@angular/material' as mat;

@mixin asset-property-list-theme($theme){
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  :host {
    button.template-edit-button {
      @include mat.typography-level($zonar-default-typography, body-2);
      color: mat.get-color-from-palette($zonar-blue, default);
    }
  }
}
