@use 'node_modules/@angular/material' as mat;

@mixin checkbox-list-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);

  app-checkbox-list {
    .data-list-container {
      .data-name {
        background: mat.get-color-from-palette($zonar-gray, 200);
        font-size: 0.75rem;
        color: mat.get-color-from-palette($zonar-gray, default);
      }

      .section-list {
        font-size: 0.875rem;
      }

      .section-item {
        border-bottom-color: mat.get-color-from-palette($zonar-gray, 300);
      }
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: mat.get-color-from-palette($zonar-gray, 400);
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: mat.get-color-from-palette($zonar-gray, 400);
    }
  }
}
