@use 'node_modules/@angular/material' as mat;

@mixin card-list-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $foreground: map-get($theme, foreground);

  .card-list-container {
    mat-icon {
      color: mat.get-color-from-palette($primary, default);
    }
    
    .divider-container {
      mat-divider {
        border-right-color: mat.get-color-from-palette($primary, lighter);
      }
    }
  }
}
