@use 'node_modules/@angular/material' as mat;

@mixin information-dialog-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);

  app-information-dialog {
    .dialog-title {
      @include mat.typography-level($zonar-default-typography, subheading-1);
      color: mat.get-color-from-palette($zonar-gray, 900);
      font-size: 1.25rem;
      line-height: 2rem;
    }

    .dialog-message {
      @include mat.typography-level($zonar-default-typography, body-1);
      color: mat.get-color-from-palette($zonar-gray, 900);
      line-height: 1.5rem;
    }

    .raised-button {
      @include mat.typography-level($zonar-default-typography, body-1);
      color: mat.get-color-from-palette($zonar-gray, 50);
      background-color: mat.get-color-from-palette($zonar-blue, default);
    }

    .stroked-button {
      @include mat.typography-level($zonar-default-typography, body-1);
      color: mat.get-color-from-palette($zonar-blue, default);
      background-color: mat.get-color-from-palette($zonar-gray, 50);
    }
  }
}
