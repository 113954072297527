@use 'node_modules/@angular/material' as mat;

@mixin search-theme($theme) {
  $primary: map-get($theme, primary);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  app-search {
    .mat-form-field {
      background-color: mat.get-color-from-palette($primary, 50);
    }

    .search-container .mat-form-field-appearance-outline .mat-form-field-outline,
    .search-container .mat-form-field-appearance-outline .mat-form-field-outline-thick {
      color: mat.get-color-from-palette($primary, 50);
    }

    .search-icon,
    .mat-icon-button {
      color: mat.get-color-from-palette($primary, default);
    }

    input {
      font-size: 0.875rem;
      color: mat.get-color-from-palette($primary, 900); // Partially Focused and Activated state, #292929
      &:focus::placeholder {
        color: mat.get-color-from-palette($zonar-gray, 600); // Focused state, #A2AAAF
      }
    }

    .mat-input-element::placeholder {
      color: mat.get-color-from-palette($primary, default); // Enabled state, #5B6770
    }
  }
}
