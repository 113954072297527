@use 'node_modules/@angular/material' as mat;

@mixin single-grid-panel-theme($theme){
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  .grid-panel {
    .grid-panel-title {
      @include mat.typography-level($zonar-default-typography, subheading-2);
      color: mat.get-color-from-palette($zonar-gray, darker);
      line-height: 2.5rem;
    }

    .grid-panel-desc {
      @include mat.typography-level($zonar-default-typography, body-1);
      color: mat.get-color-from-palette($zonar-gray, default);
      line-height: 1.5rem;
    }

    .grid-panel-data {
      .data-header {
        @include mat.typography-level($zonar-default-typography, caption);
        color: mat.get-color-from-palette($zonar-gray, darker);
        line-height: 1rem;
      }

      .data-cell {
        .data-cell-text {
          @include mat.typography-level($zonar-default-typography, subheading-1);
          color: mat.get-color-from-palette($zonar-gray, darker);
          font-size: 1.25rem;
          line-height: 2rem;
        }
      }
    }
  }
}
