@use 'node_modules/@angular/material' as mat;

@mixin create-company-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $foreground: map-get($theme, foreground);

  app-create-company mat-icon {
    &.successful-creation-icon {
      color: green;
    }

    &.unsuccessful-creation-icon {
      color: red;
    }
  }

  app-create-company .mat-form-field-appearance-outline {
    .mat-form-field-wrapper .mat-form-field-outline {
      color: mat.get-color-from-palette($primary, 500);
    }
    .mat-form-field-label {
      color: mat.get-color-from-palette($primary, default);
      font-size: 0.875rem;
    }
  }

  .create-company-container {
    .short-name-title {
      font-weight: bold;
    }

    .input-container {
      .short-name-hint {
        color: mat.get-color-from-palette($zonar-gray, 700);
        font-size: 0.75rem;
      }

      .short-name-input .mat-form-field-outline {
        background: repeating-linear-gradient(-45deg, $white, $white 1rem, $smoke 1rem, $smoke 1.5rem);
      }
    }
  }

  app-create-company .externally-managed-url {
    @include mat.typography-level($zonar-default-typography, body-2);
  }

  app-create-company .externally-managed-company .mat-form-field.mat-form-field-disabled{
    font-style: italic;
    opacity: 0.6;
  }

  .edit-icon {
    color: mat.get-color-from-palette($foreground, edit-icon);
  }

  .company-tooltip {
    color: mat.get-color-from-palette($accent, 900);
  }

  .allow-line-break::first-line {
    font-weight: bold;
    font-size: larger;
  }

  .tooltip-size {
    font-size: 1.5rem;
  }

  .common-input {
    font-size: 0.875rem;
    color: mat.get-color-from-palette($primary, default);
  }

  .create-title {
    @include mat.typography-level($zonar-default-typography, subheading-1);
  }

  .mat-body {
    color: mat.get-color-from-palette($primary, 900);
    @include mat.typography-level($zonar-default-typography, body-2);
    font-weight: normal;
  }

  // This set css is applied to all cancel / OK button pair in all create / edit screens
  .button-container {
    button {
      @include mat.typography-level($zonar-default-typography, body-1);
      font-weight: bold;
    }
  }

  .background-stripes {
    .profile-container {
      background: repeating-linear-gradient(-45deg, $white, $white 1rem, $smoke 1rem, $smoke 1.5rem);
    }
  }

  .custom-tooltip .custom-tooltiptext {
    /* Conti/Conti Dark Grey */
    color: mat.get-color-from-palette($zonar-gray, 100);
    background: mat.get-color-from-palette($zonar-gray, 700);
    font-size: mat.font-size($typography, caption);
  }

  .na-custom-tooltip-warn-color {
    color: $warn;
  }
}
